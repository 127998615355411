@import url("https://fonts.googleapis.com/css?family=Dancing+Script|Halant|Nunito+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgb(245, 245, 245);
}

.container {
  margin: auto;
}
.nav-container {
  padding: 10px 0;
  background-color: #f38b06;
}

.logo-img {
  object-fit: cover;
  max-width: 100%;
}

.logoContainer {
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {
  .logoContainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #navButtonId {
    color: green;
  }
  .authForm {
    padding: 0 20px;
  }
}
a,
a:active,
a:visited {
  text-decoration: none;
  color: #fff;
}
#kristenImg {
  width: 130px;
  border-radius: 50%;
  border: #f38b06 solid 4px;
  transform: translateY(-50px);
}

#contact {
  background-color: #f38b06;
  color: #fff;
  padding: 20px 10px 60px 10px;
}

.home-section {
  padding: 20px 20px;
}

@media only screen and (max-width: 600px) {
  .padMeRight {
    padding: 20px 30px 20px 30px;
  }

  .padMeLeft {
    padding: 20px 30px 20px 30px;
  }
}

.paypal-button {
  padding-top: 50px;

  text-align: center;
}
